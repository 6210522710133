// ----------------------------- Colors System-----------------------------
$text-primary: rgb(111, 112, 114);                // #6F7072
$text-secondary: rgb(52, 52, 52);                 // #343434FF
$text-tertiary: rgb(66, 66, 67);                  // #424243FF
$text-graph: rgb(157, 157, 157);                  // #9D9D9DFF
$red-primary: rgb(194, 27, 23);                   // #C23B33
$red-secondary: rgb(117, 33, 39);                 // #752127FF
$red-secondary-light: rgb(238, 144, 123);         // #EE907B
$red-secondary-dark: rgb(142, 42, 42);            // #8E2A2A
$red-tertiary: rgb(142, 18, 48);                  // #8E1230FF
$red-quaternary: rgb(223, 103, 68);               // #DF6744FF
$red-alt: rgb(224, 146, 141);                     // #E0928DFF
$red-scrollbar: rgb(239,220,222);                 // #EFDCDEFF
$red-light: rgb(252, 250, 250);                   // #FCFAFAFF
$red-lighter: rgb(255, 221, 219);                 // #FFDDDBFF
$red-lightest: rgb(255, 247, 247);                // #FFF7F7FF
$red-tomato: rgb(243,98,66);                      // #F36242FF
$alert-background: rgb(239, 220, 222);            // #EFDCDEFF
$error-background: rgb(237, 211, 200);            // #EDD3C8FF

$selected-background: rgb(237, 237, 237);         // #EDEDEDFF
$reason-error-background: rgb(249, 249, 249);     // #F9F9F9FF
$interaction-btn-background: rgb(255, 245, 244);  // #FFF5F4FF

$dashed-line: rgb(207, 207, 207);                 // #CFCFCFFF
$shadow: rgb(181, 181, 181);                      // #B5B5B5FF
$separator: rgb(215, 215, 215);                   // #D7D7D7FF
$border-color-primary: rgb(151, 151, 151);        // #979797FF
$color-secondary-contrast-rgba: rgba(255, 255, 255, 0.4); // #FFFFFF66
$color-medium-rgba: rgba(118,119,122,0.2);        // #76777A33
$color-medium-tint-rgba: rgba(154,154,154,0.15);


$border-color-tertiary: rgb(225, 141, 137);       // #E18D89
$disabled-color: rgb(214, 219, 225);              // #D6DBE1
$inactive-color: rgb(160, 160, 160);              // #A0A0A0
$status-active: rgb(105, 162, 91);                // #69A25BFF
$status-inactive: $disabled-color;
$sea-green: rgb(61, 145, 94);                     // #3D915EFF
$stepper-background: rgb(244, 244, 244);          // #F4F4F4FF


// Funds Colors
$fund-a: rgb(186, 78, 79);                        // #BA4E4FFF
$fund-b: rgb(243, 174, 2);                        // #F3AE02FF
$fund-c: rgb(106, 161, 90);                       // #6AA15AFF
$fund-d: rgb(100, 158, 178);                      // #649EB2FF
$fund-e: rgb(72, 126, 239);                       // #487EEFFF

$fund-a-light: rgba(186, 78, 79, 0.15);           // #BA4E4F26
$fund-b-light: rgba(243, 174, 2, 0.15);           // #F3AE0226
$fund-c-light: rgba(106, 161, 90, 0.15);          // #6AA15A26
$fund-d-light: rgba(100, 158, 178, 0.15);         // #649EB226
$fund-e-light: rgba(72, 126, 239, 0.15);          // #487EEF26

// Accounts Colors
$account-cco: rgb(46, 113, 193);  // CCICO        // #2E71C1FF
$account-cav: rgb(52, 66, 91);    // CAV          // #34425BFF
$account-cai: rgb(255, 139, 142); // CAI          // #FF8B8EFF
$account-ccv: rgb(61, 145, 94);   // CCICV        // #3D915EFF
$account-cdc: rgb(235, 104, 58);  // CCIDC        // #EB683AFF
$account-caf: rgb(254, 167, 48);  // CCIAV        // #FEA730FF
$account-cvc: rgb(97, 73, 170);   // APVC         // #6149AAFF

// Reuses
$white: white;
$black: black;
$text-error: $red-primary;

// New Color System - Figma
$color-dark-01: rgb(96, 112, 129);               // ##607081
$color-dark-02: rgb(44, 52, 60);                 // #2C343C
$color-light-01: rgb(246, 247, 248);             // #F6F7F8
$color-light-02: rgb(166, 177, 189);             // #A6B1BD
$color-light-03: rgb(166, 177, 189);             // #A6B1BD
$color-light-04: rgb(247, 247, 247);             // #F7F7F7

$color-status-success-mid: rgb(6, 194, 112);     // #06C270
$color-status-success-mid-gamma: rgba(6, 194, 112, 0.2);

// Other
$red-secondary-degraded: rgb(142, 42, 42);
$dark-light: rgb(96, 112, 129);
$grey-mid-dark: rgb(112, 112, 112);               // #707070
$shadow-lightest: rgba(0, 0, 0, 0.1);
$collector-color: rgb(238, 93, 100);
$input-color: rgb(26, 26, 26);
$red-dark: #930800;
$transparency: rgba(0, 0, 0, 0.17);

// Profitability colors
$customer-contribution: rgb(246, 196, 67);         // #F6C443
$profit: rgb(135, 148, 200);                       // #8794C8
$withdrawal: rgb(222, 111, 90);                    // #DE6F5A

// Timer colors

$status-alert-mid: rgba(255, 205, 0, 1);
$status-alert-dark: rgba(117, 98, 1, 1);
$status-alert-light: rgba(255, 254, 229, 1);

// Elevations
$elevation-02: 0px 0px 1px rgba(96, 112, 129, 0.2), 0px 1px 4px rgba(96, 112, 129, 0.3);
$elevation-06: 0px 2px 8px rgba(96, 112, 129, 0.2), 0px 15px 32px rgba(96, 112, 129, 0.3);
$box-shadow: 0 6px 12px 0 rgba(0,0,0,0.15);
$box-shadow-04: 0 4px 4px rgba(0,0,0,0.25);

// Gradients
$header-gradient: linear-gradient(270deg, $red-dark 0%, $red-primary 100%);
$header-gradient-mobile: linear-gradient(30deg, $red-dark 0%, $red-primary 100%);
$side-menu-gradient: linear-gradient(238.2deg, $red-primary 0%, #930800 85.46%);



// ----------------------------- SVG -----------------------------

:root {
  --icon-valid: url(/assets/icons/i-valid.svg);
  --icon-invalid: url(/assets/icons/i-invalid.svg);
}

// Legacy

$turquoise: rgb(61, 142, 145);
$turquoise-light: rgba(185, 215, 224, 0.3);

$white: rgba(255, 255, 255, 1); // **
$white-smoke: rgba(239, 239, 239, 0.6); // **
$white-smoke-transparent: rgba(255, 255, 255, 0.8); // **

$grey-darkest: rgb(51, 51, 51); // **
$grey-darker: rgb(67, 67, 67); // **
$grey-dark: rgb(100, 100, 100); // **
$grey-mid-dark: rgb(107, 107, 107); // **
$grey: rgb(154, 154, 154);
$grey-white: rgb(245, 245, 245);
$grey-white1: #F9F9F9;
$grey-white2: #EDEDED;
$grey-white3: #D9D9D9;
$white-semitransparent: rgba(255, 255, 255, 0.3);

$white-gradient: linear-gradient(180deg, $white 30%, $white-semitransparent 70%);

// Fonts
$font-regular: "Roboto-Regular";
$font-medium: "Roboto-Medium";
$font-bold: "Roboto-Bold";

// Media query
$break-xs: 360px;
$break-small-mid: 400px;
$break-small: 480px;
$break-medium: 720px;
$break-large: 795px;
$break-xlarge-mid: 897px;
$break-xlarge: 1000px;
$break-xxlarge-mid: 1220px;
$break-xxlarge: 1440px;
$width-mobile: 415px;

// Funds Cards
$max-width-card: 441px;
$min-width-card: 300px;
