@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'variables.scss';

/* You can add global styles to this file, and also import other style files */

// ----------------------------- Fonts -----------------------------
@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// ----------------------------- Titles -----------------------------

.highlight {
  font-family: $font-bold;
}

.align-center {
  text-align: center !important;
  justify-content: center;
}

.red-title {
  font-family: $font-regular;
  color: $red-primary;
  margin: 10px !important;
}

.version {
  position: fixed;
  bottom: 20px;
  font: 10px $font-regular;
  right: 5px;
  padding: 7px 7px 7px 2px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background: white;
  border-radius: 0 5px 5px 0;
}

h1 {
  @extend .red-title;
  font-size: 32px;
}

h2 {
  @extend .red-title;
  font-size: 28px;

  @media screen and (max-width: $break-xlarge) { font-size: 20px; }
}

h3 {
  @extend .red-title;

  color: $text-primary;
  font-size: 24px;
}

h4 {
  @extend .red-title;

  color: $text-primary;
  font-size: 22px;
}

h5 {
  @extend .red-title;
  font-size: 20px;
}

h6 {
  @extend .red-title;

  color: $text-primary;
  font-size: 16px;

  @media screen and (max-width: $break-xlarge) { font-size: 14px; }
}

.no-wrap-text {
  width: fit-content;
  white-space: nowrap;
}

// ----------------------------- Form input -----------------------------


.mat-option-text {
  font: 12px $font-regular;
}

.mat-mdc-select-arrow {
  @extend .icon;
  width: 20px !important;
  height: 15px !important;
  margin: 0 0 !important;
  border-top: 0 !important;
  color: transparent;
  background-image: url("./assets/icons/i-mat-arrow-select.svg") !important;
}


.mat-mdc-select-arrow-wrapper {
  display: flex !important;
  align-items: flex-end !important;
}

.mat-mdc-select-arrow svg {
  display: none;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: unset !important;
 }

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: $red-primary !important;
}

.mat-mdc-form-field {

  .mdc-text-field--filled:not(.mdc-text-field--disabled), .mat-mdc-form-field-focus-overlay{
    background-color: unset;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: $red-secondary;
  }

  .mdc-text-field {
    padding: 0px;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0px;
  }

  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix{
    align-self: unset;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-bottom: 4px;
  }
  
  .mat-mdc-form-field-infix {
    min-height: unset;
    .mdc-floating-label {
      font-family: $font-bold;
      color: $text-secondary;
    }
  }

  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, 
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) 
  .mdc-floating-label--float-above{
    color: unset;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: unset;
  }


  .hint {
    font: 14px $font-bold;
    color: $text-primary;
  }

  .mat-form-field-infix { padding: 12px 0 3px; }

  .mat-mdc-input-element {
    font: 16px/25px $font-regular;
    caret-color: $red-secondary;
  }

  .mat-form-field-placeholder {
    font: 19px/25px $font-regular;
    color: $red-secondary;
    opacity: 0.7;
  }
 
  &.mat-form-field-invalid .mat-form-field-label, &.mat-focused .mat-form-field-label, .mat-form-field-label {
    font: 16px $font-regular;
  }
 
 


  .i-validator {
    display: block;
    width: 16px;
    height: 16px;
    background: var(--icon-valid) center/contain no-repeat;
  }

  &.mat-form-field-invalid .i-validator { background-image: var(--icon-invalid); }

  &.ng-untouched .i-validator { display: none; }

  &.mat-form-field-disabled .i-validator { display: none; }

  .mat-mdc-form-field-error {
    font: 14px $font-bold;
    color: $red-primary;
  }
}

mat-form-field {
  .mdc-text-field--filled .mdc-line-ripple::after{
    border-bottom-color: $red-secondary;
  }
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: $red-secondary;
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
    border-bottom-color: $red-secondary;
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: $red-secondary;
  }
}

/* Focused */


/* Invalid */
.mat-mdc-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: $text-secondary;
   
    .mat-form-field-required-marker {
      color: inherit;
    }

    .mat-form-field-ripple {
      background-color: $red-primary;
    }
  }
}

/* Disabled */
.mat-form-field-appearance-legacy.mat-form-field-disabled {

  .mat-form-field-label {
    color: $disabled-color;
  }

  .mat-form-field-underline {
    background-color: $disabled-color !important;
    background-image: none;
  }
}

// ------------------------------- Google Maps  ------------------------------

agm-map {
  position: relative;
  height: 240px;
  width: 100%;
}

// ------------------------------- Utils ------------------------------

.margin-error  {
  margin-bottom: 20px;
}

// ----------------------------- Main Components -----------------------------


body {
  margin: 0;
  background-color: white;
  color: $text-primary;
  font: 15.5px $font-regular;
}

app-footer {
  display: block;
  padding: 30px 0 90px;
}

app-header-biodesktop,
app-header {
  background: $header-gradient;
  display: block;
  height: 100px;
  border-radius: 0px 0px 25px 0px;
  @media (max-width: 800px) {
    height: 74px;
  }
  @media (max-width: 1000px) {
    border-radius: 0px !important;
    background: $header-gradient-mobile;
  }
}

app-continue,
app-biodesktop,
app-contact,
app-contact-successful,
app-online {
  display: flex !important;
  justify-content: center;
}

app-confirm,
app-biodesktop,
app-continue,
app-assisted,
app-contact-successful,
app-questions,
app-transfer {
  margin: -0px auto 0;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 2px 12px 0 $shadow;
  display: block;
  overflow: hidden;
  width: 90%;
  max-width: 1300px;
  box-sizing: border-box;
  min-height: 400px;
}

app-unassisted, app-transfer, app-confirm { padding: 25px 50px; }

app-confirm,
app-transfer {
  @media (max-width: $width-mobile) {
    padding: 0;
    margin: 0;
    border-radius: 0;
    width: 100%;
  }
  @media (max-width: 893px) {
    padding: 20px;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;

  .current-affiliate{
    width: fit-content;
    text-align: center;
    font: 14px $font-regular;
    padding-top: 10px;
  }

  .action-title {
    font: 14px $font-bold;
    color: $red-primary;
    padding-top: 30px;
  }

  .buttons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .continue-btn{
      width: 315px;
      padding: 12px;
      border-radius: 25px;
      margin-top: 40px;
      font: 14px $font-bold;
    }
    .back-btn{
      margin-top: 33px;
      font: 14px $font-bold;
    }
  }
}

.aligned-box {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0 2.9%;

  @media screen and (max-width: $break-xlarge) {
    justify-content: center;
  }

  .i-arrow-back {
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;
  }

  .i-download {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

  }
}

// ----------------------------- Icons -----------------------------

.icon {
  background: no-repeat center;
}

.i-loading {
  @extend .icon;
  background-image: url('./assets/icons/i-loading.gif');
  height: 400px;
  width: 100%;
}

.i-edit-white {
  @extend .icon;
  background-image: url("./assets/icons/i-edit-white.svg");
}

.i-send {
  @extend .icon;
  background-image: url('./assets/icons/i-send.svg');
  width: 20px;
  height: 20px;
}

.i-send-white {
  @extend .icon;
  background-image: url("./assets/icons/i-send-white.svg");
  width: 20px;
  height: 20px;
}

.i-download {
  @extend .icon;
  background-image: url('./assets/icons/i-download.svg');
  width: 20px;
  height: 20px;
}

.i-exchange {
  @extend .icon;
  background-image: url('./assets/icons/i-exchange.svg');
  height: 30px;
  width: 30px;
  margin: 35px 20px;
}

.i-denied {
  @extend .icon;
  background-image: url('./assets/icons/i-denied.svg');
  height: 30px;
  width: 30px;
  margin: 35px 20px;
}

.i-denied-red-primary{
  @extend .icon;
  background-image: url("./assets/icons/i-denied-red-primary.svg");
  height: 20px;
  width: 20px;
  margin: 35px 20px;
}

.i-denied-white{
  @extend .icon;
  background-image: url("./assets/icons/i-denied-white.svg");
  height: 20px;
  width: 20px;
  margin: 35px 20px;
}

.i-delete {
  @extend .icon;
  background-image: url('./assets/icons/i-delete.svg');
  height: 40px;
  width: 40px;
  margin: 26px auto;
}

.i-success {
  @extend .icon;
  background-image: url('./assets/icons/i-success.svg');
  height: 250px;
  width: 350px;
}

.i-alert-alt {
  @extend .icon;
  background-image: url('./assets/icons/i-alert-alt.svg');
  height: 40px;
  width: 40px;
  margin: 20px 0 26px 26px;
  background-size: contain;
}

.i-alert-red-primary {
  @extend .icon;
  background-image: url('./assets/icons/i-alert-red-primary.svg');
  height: 40px;
  width: 40px;
  background-size: contain;
}

.i-location {
  @extend .icon;
  background-image: url('./assets/icons/i-location.svg');
  height: 48px;
  width: 48px;
  margin: 5px;
  background-size: contain;
}

.i-location-red {
  @extend .icon;
  background-image: url('./assets/icons/i-location-red.svg');
}

.i-cross {
  @extend .icon;
  height: 50px;
  width: 50px;
  margin: 5px;
  background: $white-semitransparent url('./assets/icons/i-cross.svg');
}

.i-info {
  @extend .icon;
  background-image: url('./assets/icons/i-info.svg');
  height: 30px;
  width: 30px;
}

.i-denied, .i-exchange {
  @media (max-width: 990px) {
    margin: 35px auto;
  }
}

.i-alert-biometric {
  @extend .icon;
  background-image: url('./assets/icons/i-alert-alt.svg');
  height: 40px;
  width: 40px;
}

.i-alert-large {
  @extend .icon;
  background-image: url('./assets/icons/i-alert-red-primary.svg');
  height: 55px;
  width: 55px;
  margin: 20px;
  background-size: contain;
}

.i-alert-extra-large {
  @extend .icon;
  background-image: url('./assets/icons/i-alert-red-primary.svg');
  height: 70px;
  width: 70px;
  background-size: contain;
}

.i-alert-status {
  @extend .icon;
  background-image: url('./assets/icons/i-alert-status.svg');
  height: 10px;
  width: 10px;
  padding: 20px 12px 20px 12px;
  margin: 0 8px;
  background-size: contain;
}

.i-lock {
  @extend .icon;
  background-image: url('./assets/icons/i-lock.svg');
  height: 50px;
  width: 50px;
  margin: 10px;
  background-size: contain;
}

.i-time {
  @extend .icon;
  background-image: url('./assets/icons/i-time.svg');
  height: 130px;
  margin-top: 15vh;
  width: 350px;
}

.i-warning {
  @extend .icon;
  background-image: url('./assets/icons/i-warning.svg');
  height: 50px;
  width: 50px;
  margin: 10px;
  background-size: contain;
}

.i-arrow-transfer {
  @extend .icon;
  background-image: url('./assets/icons/i-arrow-transfer.svg');
  width: 200px;
  height: 100px;
  margin: auto;
  @media screen and (max-width: $break-large) { margin: 30px auto; }
}

.i-arrow-back {
  @extend .icon;
  background-image: url('./assets/icons/i-arrow-back.svg');
  width: 40px;
  height: 40px;
}

.i-arrow-steps-red {
  @extend .icon;
  background-image: url('./assets/icons/i-arrow-steps-red.svg');
  width: 20px;
  height: 20px;
}

.i-arrow-steps-black {
  @extend .icon;
  background-image: url('./assets/icons/i-arrow-steps-black.svg');
  width: 20px;
  height: 20px;
}

.i-arrow-steps-disabled {
  @extend .icon;
  background-image: url('./assets/icons/i-arrow-steps-disabled.svg');
  width: 20px;
  height: 20px;
}

.i-arrow-red {
  @extend .icon;
  background-image: url('./assets/icons/i-arrow-red.svg');
  width: 40px;
  height: 40px;
}

.i-arrow-white {
  @extend .icon;
  background-image: url('./assets/icons/i-arrow-white.svg');
  width: 40px;
  height: 40px;
}

.i-call {
  @extend .icon;
  background-image: url('./assets/icons/i-call.svg');
  width: 15px;
  height: 15px;
}

.i-call-white {
  @extend .icon;
  background-image: url('./assets/icons/i-call-white.svg');
  width: 15px;
  height: 15px;
}

.i-woman-check {
  @extend .icon;
  background-image: url('./assets/icons/i-woman-check.svg');
  width: 15px;
  height: 15px;
}

.i-woman-check-green {
  @extend .icon;
  background-image: url('./assets/icons/i-woman-check-green.svg');
  width: 15px;
  height: 15px;
}

.i-employer {
  @extend .icon;
  background-image: url('./assets/icons/i-employer.svg');
  width: 15px;
  height: 15px;
}

.i-maps {
  @extend .icon;
  background-image: url('./assets/icons/i-maps.svg');
  width: 15px;
  height: 15px;
}

.i-mail {
  @extend .icon;
  background-image: url('./assets/icons/i-mail.svg');
  width: 15px;
  height: 15px;
}

.i-mail-white {
  @extend .icon;
  background-image: url('./assets/icons/i-mail-white.svg');
  width: 15px;
  height: 15px;
}

.i-chevron {
  @extend .icon;
  background-image: url('./assets/icons/i-chevron.svg');
  width: 25px;
  height: 25px;
}

.i-check {
  @extend .icon;
  background-image: url('./assets/icons/i-check.svg');
  width: 28px;
  height: 28px;
}

.i-check-red {
  @extend .icon;
  background-image: url('./assets/icons/i-check-red.svg');
  width: 40px;
  height: 60px;
}

.i-cross-red {
  @extend .icon;
  background-image: url('./assets/icons/i-cross-red.svg');
  width: 40px;
  height: 60px;
}

.i-check-empty {
  @extend .icon;
  background-image: url('./assets/icons/i-empty-check.svg');
  width: 28px;
  height: 28px;
}

.i-check-red-tertiary {
  @extend .icon;
  background-image: url('./assets/icons/i-check-red-tertiary.svg');
}

.i-check-white {
  @extend .icon;
  background-image: url('./assets/icons/i-check-white.svg');
  width: 19px;
  height: 19px;
}

.i-close {
  @extend .icon;
  background-image: url('./assets/icons/i-close.svg');
  width: 25px;
  height: 25px;
}

.i-spinner {
  @extend .icon;
  background-image: url('./assets/icons/i-spinner.svg');
  width: 32px;
  height: 32px;
}

.i-calendar {
  @extend .icon;
  background-image: url('./assets/icons/i-calendar.svg');
  width: 15px;
  height: 15px;
  background-size: contain;
}

.i-folder {
  @extend .icon;
  background-image: url('./assets/icons/i-folder.svg');
  width: 15px;
  height: 15px;
  background-size: contain;
}

.i-certificates-red {
  @extend .icon;
  background-image: url('./assets/icons/i-certificates-red.svg');
  width: 19px;
  height: 19px;
}

.i-attached-file {
  @extend .icon;
  background-image: url('./assets/icons/i-attached-file.svg');
  width: 30px;
  height: 30px;
}

.i-attached-file-white {
  @extend .icon;
  background-image: url('./assets/icons/i-attached-file-white.svg');
  width: 30px;
  height: 30px;
}

.i-doc {
  @extend .icon;
  background-image: url('./assets/icons/i-doc.svg');
  width: 15px;
  height: 15px;
}

.i-dollar {
  @extend .icon;
  background-image: url('./assets/icons/i-dollar.svg');
  width: 15px;
  height: 15px;
}

.i-dollar-2 {
  @extend .icon;
  background-image: url('./assets/icons/i-dollar-2.svg');
  width: 64px;
  height: 64px;
  display: inline-block;
}

.i-percentage {
  @extend .icon;
  background-image: url('./assets/icons/i-percentage.svg');
  width: 15px;
  height: 15px;
}

.i-saving-pig {
  @extend .icon;
  background-image: url('./assets/icons/i-saving-pig.svg');
  width: 15px;
  height: 15px;
}

.i-add {
  @extend .icon;
  background-image: url('./assets/icons/i-add.svg');
}

.i-close-outline {
  @extend .icon;
  background-image: url('./assets/icons/i-close-outline.svg');
}

.i-user {
  @extend .icon;
  background-image: url('./assets/icons/i-user.svg');
}

.i-check-oval {
  @extend .icon;
  background-image: url('./assets/icons/i-check-oval.svg');

  &.disabled {
    background-image: url('./assets/icons/i-check-oval-disabled.svg');
  }
}

.i-ingress {
  @extend .icon;
  background-image: url('./assets/icons/i-ingress.svg');
}

.i-out {
  @extend .icon;
  background-image: url('./assets/icons/i-out.svg');
}

.i-rim {
  @extend .icon;
  background-image: url('./assets/icons/i-rim.svg')
}

.i-whatsapp {
  @extend .icon;
  background-image: url('./assets/icons/i-whatsapp.svg')
}

.i-transfer {
  @extend .icon;
  background-image: url('./assets/icons/i-transfer.svg');
}

.i-search {
  @extend .icon;
  background-image: url('./assets/icons/i-search.svg');

  &.active {
    background-image: url('./assets/icons/i-search-active.svg');
  }
}

.i-search-white {
  @extend .icon;
  background-image: url('./assets/icons/i-search-white.svg');
}

.i-menu {
  @extend .icon;
  background-image: url('./assets/icons/i-menu.svg');
}

.i-portfolio {
  @extend .icon;
  background-image: url('./assets/icons/i-portfolio.svg');
}

.i-phone-2 {
  @extend .icon;
  background-image: url('./assets/icons/i-phone-2.svg');
}

.i-phone-white {
  @extend .icon;
  background-image: url('./assets/icons/i-phone-white.svg');
}

.i-phone-not-call {
  @extend .icon;
  background-image: url('./assets/icons/i-phone-not-call.svg');
}

.i-email {
  @extend .icon;
  background-image: url('./assets/icons/i-email.svg');

  &.white {
    background-image: url('./assets/icons/i-email-white.svg')
  }
}

.i-right-arrow {
  @extend .icon;
  background-image: url('./assets/icons/i-right-arrow.svg');
}

.i-gift {
  @extend .icon;
  background-image: url('./assets/icons/i-gift.svg');
}

.i-gift-white {
  @extend .icon;
  background-image: url('./assets/icons/i-gift-white.svg');
}

.i-info-circle {
  @extend .icon;
  background-image: url('./assets/icons/i-info-circle.svg');
}

.i-info-circle-white {
  @extend .icon;
  background-image: url('./assets/icons/i-info-circle-white.svg');
}

.i-two-users {
  @extend .icon;
  background-image: url('./assets/icons/i-two-users.svg');
}

.i-two-users-white {
  @extend .icon;
  background-image: url('./assets/icons/i-two-users-white.svg');
}

.i-alert-outline-red-primary {
  @extend .icon;
  background-image: url('./assets/icons/i-alert-outline-red-primary.svg');
  height: 24px;
  width: 24px;
  background-size: contain;
}

.i-exchange-red-primary {
  @extend .icon;
  background-image: url('./assets/icons/i-exchange-red-primary.svg');
  height: 17px;
  width: 17px;
  background-size: contain;
}

.i-apv {
  @extend .icon;
  background-image: url('./assets/icons/i-apv.svg');
  height: 50px;
  width: 50px;
  margin: 10px;
  background-size: contain;
}

.i-cav {
  @extend .icon;
  background-image: url('./assets/icons/i-cav.svg');
  height: 50px;
  width: 50px;
  margin: 10px;
  background-size: contain;
}

.i-cav-disabled {
  @extend .icon;
  background-image: url('./assets/icons/i-cav-disabled.svg');
  height: 50px;
  width: 50px;
  margin: 10px;
  background-size: contain;
}

.i-plus {
  @extend .icon;
  background-image: url('./assets/icons/i-plus.svg');
  width: 25px;
  height: 25px;
}

.i-plus-close {
  @extend .icon;
  background-image: url('./assets/icons/i-plus-close.svg');
  width: 25px;
  height: 25px;
}

.i-telephone {
  @extend .icon;
  background-image: url('./assets/icons/i-telephone.svg');
  width: 25px;
  height: 25px;
}

.i-success-tick {
  @extend .icon;
  background-image: url('./assets/icons/i-success-tick.svg');
  width: 25px;
  height: 25px;
}

.i-unsuccess-cross {
  @extend .icon;
  background-image: url('./assets/icons/i-unsuccess-cross.svg');
  width: 25px;
  height: 25px;
}

.i-authorized {
  @extend .icon;
  background-image: url('./assets/icons/i-authorized.svg');
  width: 37px;
  height: 35px;
}

// ----------------------------- Images -----------------------------

.image {
  @extend .icon;
  background-size: cover;
}

.logo-pv-red-alt {
  @extend .icon;
  background-image: url('./assets/img/logo-pv-red-alt.svg');
  height: 100%;
  width: 100%;
}

.logo-pv-red {
  @extend .icon;
  background-image: url('./assets/img/logo-pv-red.svg');
  height: 70px;
  width: 145px;

  @media screen and (max-width: $break-xlarge) {
    display: none;
  }
}

.logo-pv-white {
  @extend .icon;
  background-image: url('./assets/img/logo-pv-white.svg');
  height: 63px;
  width: 100px;
  margin: 20px 0;

  @media screen and (max-width: $break-large) {
    width: 95px;
    height: 37px;
  }
}

.slogan-pv {
  @extend .icon;
  //background-image: url('./assets/img/logo_campeonato.svg'); //aqui se cambia el slogan
  background-size: 100%;
  height: 100PX;
  width: 350PX;
  background-position: -10px 10px;
  margin-top: 10px;

  @media screen and (max-width: $break-large) {
    width: 158px;
    height: 47px;
  }
}

.concept-pv-grey {
  @extend .icon;
  background-image: url('./assets/img/concept-pv-grey.svg');
  height: 303px;
  width: 322px;
}

.concept-pv-white {
  @extend .icon;
  background-image: url('./assets/img/concept-pv-white.svg');
  height: 359px;
  width: 380px;
}

.contact-assistant {
  @extend .image;
  background-image: url('./assets/img/assisted-btn.png');
  height: 355px;
  width: 275px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &:focus { outline: none; }
}

.transfer-online {
  @extend .image;
  background-image: url('./assets/img/online-transfer-btn.png');
  height: 355px;
  width: 275px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &:focus { outline: none; }
}

.contact-form-picture {
  @extend .icon;
  width: 100%;
  background-position-y: -0px;
  background-size: cover;
  object-fit: cover;
}

.executive-validation-biodesktop-picture {
  @extend .icon;
  background-image: url('./assets/img/executive-validation-biodesktop.png');
  width: calc(40% - 25px);
  max-width: 350px;
  min-width: 197px;
  background-size: cover;
  background-position-x: -270px;

  @media screen and (max-width: $break-small) {
    display: none
  }
}

.client-validation-biodesktop-picture {
  @extend .icon;
  background-image: url('./assets/img/client-validation-biodesktop.png');
  width: calc(40% - 25px);
  max-width: 350px;
  min-width: 197px;
  background-size: cover;

  @media screen and (max-width: $break-small) {
    display: none
  }
}

.login-form-picture {
  @extend .icon;
  background-image: url('./assets/img/login-form.png');
  height: 797px;
  width: calc(40% - 25px);
  max-width: 350px;
  min-width: 197px;
}

.accounts-login-form-picture {
  @extend .icon;
  background-image: url('./assets/img/accounts-login-form.png');
  height: 800px;
  max-width: 757px;
  width: 100%;
  // // width: calc(40% - 25px);
  // max-width: 350px;
  // min-width: 197px;
}

.afp-capital-picture {
  @extend .image;
  background-image: url('./assets/img/afps/capital-bn.png');
  height: 31px;
  width: 95px;
}

.afp-provida-picture {
  @extend .image;
  background-image: url('./assets/img/afps/provida-bn.png');
  height: 22px;
  width: 93px;
}

.afp-habitat-picture {
  @extend .image;
  background-image: url('./assets/img/afps/habitat-bn.png');
  height: 25px;
  width: 95px;
}

.afp-cuprum-picture {
  @extend .image;
  background-image: url('./assets/img/afps/cuprum-bn.png');
  height: 24px;
  width: 94px;
}

.afp-modelo-picture {
  @extend .image;
  background-image: url('./assets/img/afps/modelo.png');
  height: 22px;
  width: 97px;
}

.afp-uno-picture {
  @extend .image;
  background-image: url('./assets/img/afps/uno-bn.png');
  height: 24px;
  width: 73px;
}

.client-login {
  @extend .image;
  background-image: url('./assets/img/client-login.png');
  margin-bottom: 35px;
  height: 187px;
  width: 100%;
}

.executive-login {
  @extend .image;
  background-image: url('./assets/img/executive-login.png');
  margin-bottom: 35px;
  height: 187px;
  width: 100%;
}

.post-venta-login {
  @extend .image;
  background-image: url('./assets/img/postventa-menu.png');
  margin-bottom: 35px;
  height: 187px;
  width: 100%;
}

.i-first {
  @extend .icon;
  background-image: url('./assets/icons/one.svg');
  width: 37px;
  height: 35px;
}

.i-second {
  @extend .icon;
  background-image: url('./assets/icons/two.svg');
  width: 37px;
  height: 35px;

  &.disabled {
    background-image: url('./assets/icons/two-disabled.svg');
  }
}

.i-third {
  @extend .icon;
  background-image: url('./assets/icons/three.svg');
  width: 37px;
  height: 35px;

  &.disabled {
    background-image: url('./assets/icons/three-disabled.svg');
  }
}

.i-information {
  @extend .icon;
  background-image: url('./assets/icons/i-information.svg');
  width: 37px;
  height: 35px;

  &.disabled {
    background-image: url('./assets/icons/i-information-disabled.svg');
  }
}

.i-money {
  @extend .icon;
  background-image: url('./assets/icons/i-money.svg');
  width: 49px;
  height: 35px;

  &.disabled {
    background-image: url('./assets/icons/i-money-disabled.svg');
  }
}

.i-success-hands {
  @extend .icon;
  background-image: url('./assets/icons/i-success-hands.svg');
  height: 218px;
  width: 238px;
  @media screen and (max-width: $break-small-mid) {
    background-size: contain;
    height: 146px;
    width: 159px;
  }
}

.i-back-gray {
  @extend .icon;
  background-image: url('./assets/icons/i-back-gray.svg');
  height: 10px;
  width: 6px;
}

.i-back-disable {
  @extend .icon;
  background-image: url('./assets/icons/i-back-disable.svg');
  height: 10px;
  width: 6px;
}

.i-close-gray {
  @extend .icon;
  background-image: url('./assets/icons/i-close-gray.svg');
  height: 24px;
  width: 24px;
}

// ----------------------------- Buttons -----------------------------

button {
  border: none;
  background-color: transparent;
  font-family: $font-medium;
  color: $grey-mid-dark;

  &:focus { outline: none; }
}

.btn {
  background-color: $red-primary;
  color: $white;
  cursor: pointer;
  border-radius: 18px;
  height: 42px;

  &.btn-small {
    height: 36px;
    padding: 5px 15px;


    &.btn-outlined {
      color: $red-secondary;
      border: 1px solid $red-secondary;
      background-color: $white;
      height: 27px;
      border-radius: 13.5px;
      font: 13.5px $font-regular;
      margin: 10px 0 auto auto;
      width: 92px;
    }
  }

  &.btn-large {
    min-width: 240px;
    padding: 0 15px;
    margin: 10px 0;
    border-radius: 22.5px;
    font-size: 14px;
    line-height: 22px;

    &.btn-outlined {
      color: $red-primary;
      border: 1px solid $red-primary;
      background-color: transparent;
      font: 13.5px $font-bold;
      &:hover {
        background-color: $red-primary;
        color: $white;
      }

      &.disabled {
        background-color: $disabled-color;
        border: 2px solid $disabled-color;
        color: $white;
      }
    }

    &.full {
      min-width: 100%;
    }
  }

  &.btn-alternative {
    box-shadow: none;
    background-color: transparent;
    color: $grey-dark;

    &.highlighted {
      color: $red-primary;
    }
  }

  &.btn-secondary {
    background-color: $white;
    color: $red-primary;
    border: 2px solid $red-primary;
    padding: 0 44px;
    margin: 40px 0 20px 0;
    border-radius: 22.5px;
    box-shadow: 0 2px 8px 0 $shadow;
    font-size: 14px;
    transition: 0.3s;

    &:hover {
      background-color: $red-primary;
      color: $white;

      &[disabled] {
        background-color: $disabled-color;
        border: 2px solid $border-color-primary;
        color: $white;
      }
    }

    &[disabled] {
      border: 2px solid $border-color-primary;
    }
  }

  &.interaction-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;

    height: 44px;
    min-width: 206px;
    background-color: $interaction-btn-background;
    color: $red-primary;
    font-size: 13px;
    line-height: 44px;

    .interaction-icon {
      margin-right: 7px;
      background-position-y: 10px;
      height: 44px;
    }
  }

  &[disabled] {
    background-color: $disabled-color;
    pointer-events: none;
    opacity: unset;
    box-shadow: none;
    border: none;
    color: $white;
  }

  @media (max-width: $width-mobile) {
    max-width: 240px;
  }
}

// ----------------------------- Titles -----------------------------

.main-title-bold {
  font: 28px $font-bold;
  color: $red-primary;
  text-align: center;

  &.header {
    color: $white;
  }

  &.description {
    color: $text-primary;
  }
}

.main-title {
  font: 20.5px $font-medium;
  margin: 10px;
  color: $red-primary;
  text-align: center;

  &.header {
    color: $white;
  }

  &.modal-description {
    font-size: 18.5px;
    color: $text-primary;
  }
}

.main-title-regular {
  font: 28px $font-regular;
  margin: 10px;
  color: $red-primary;
  text-align: center;

  &.header {
    color: $white;
    @media (max-width: $break-xlarge) {
      display: none;
    }
    @media (max-width: 577px) {
      display: none;
    }
  }

  &.description {
    color: $text-primary;
  }
}

.main-subtitle {
  color: $text-primary;
  font-size: 16px;
  margin: 10px 10px 30px;
  text-align: center !important;
}

.main-disclaimer {
  font-size: 12px;
  text-align: justify;
}

.section-title {
  color: $red-primary;
  font: 11.5px $font-bold;
  text-transform: uppercase;
  margin: 25px 0;
}

.secondary-title {
  color: $red-primary;
  font: 12.5px $font-bold;
  line-height: 20px;
}

.secondary-subtitle {
  color: $text-primary;
  font-size: 14.5px;
}

.alert-title {
  font: 16.5px $font-medium;
  letter-spacing: 0.23px;
  margin-top: 40px;
  color: $red-primary;
}

.informative-text {
  color: $text-primary;
  font-size: 13px;
  background-color: $alert-background;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px auto;
  text-align: justify;

  &.validation-error {
    margin: 0 0 15px;
    text-align: left;
    font-size: 12.5px;
    color: $red-primary;
    background-color: $error-background;
  }

  strong {
    font-family: $font-bold;
  }

  span {
    color: $red-quaternary;
    cursor: pointer;
    text-decoration: underline;
  }
}
.link {
  color: $red-quaternary;
  cursor: pointer;
  text-decoration: underline;
}


.account-section-title {
  font: 20.5px $font-medium;
  color: $text-primary;
  width: $max-width-card;
  text-align: left;
  line-height: 18px;
  border-left: 7px solid $red-secondary;
  padding: 5px 0 0 3px;
  box-sizing: border-box;
}

.container-info-client {
  border: 1px solid $border-color-primary;
  display: flex;
  align-items: stretch;
  border-radius: 5px;
  width: 90%;
  justify-content: space-between;
  @media screen and (max-width: $break-large) {
    flex-direction: column;
    width: 90%;
    min-width: $min-width-card;
    max-width: $max-width-card;
  }

  label {
    display: block;
    text-transform: uppercase;
  }

  .title {
    width: 100%;
    color: $grey-darker;
    font-family: $font-bold;
    font-size: 10px;
    letter-spacing: 0.24px;
    line-height: 12px;
  }

  .info-client {
    color: $grey-mid-dark;
    font-family: $font-bold;
    font-size: 14px;
    letter-spacing: 0.34px;
    line-height: 17px;
  }

  .info-section {
    padding: 8px 14px;
    width: inherit;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid $border-color-primary;

    &:nth-last-child(2) {
      border: none;
    }

    &.desktop {
      @media screen and (max-width: $break-large) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      width: 90%;
      min-width: $min-width-card;
      max-width: $max-width-card;
      margin: 5px auto;
      @media screen and (max-width: $break-large) {
        display: flex;
      }
    }

    @media screen and (max-width: $break-large) {
      width: initial;
      border-right: none;
      border-bottom: 1px solid $border-color-primary;
      &:last-child {
        border: none;
      }
    }
  }
}

// ----------------------------- Account Cards -----------------------------

[hidden] {
  display: block !important;
  visibility: hidden;
}

.container-account-type-title,
.container-section-title {
  width: 100%;
  margin: 30px 0 3px;
}

.account-type-title {
  text-align: center;
  width: calc(50% - 25px);
  max-width: $max-width-card;
  border-radius: 5px;
  color: $white;
  font: 13.5px $font-bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 9px 0;
  box-sizing: border-box;
  @media screen and (max-width: $break-large) {
    width: 90%;
    max-width: $max-width-card;
    min-width: $min-width-card;
    padding: 9px 22px;
    margin: 5px 0;
    overflow: hidden;
  }

  &.type-ccico { background-color: $account-cco; }

  &.type-cav { background-color: $account-cav; }

  &.type-cai { background-color: $account-cai; }

  &.type-ccicv { background-color: $account-ccv; }

  &.type-ccidc { background-color: $account-cdc; }

  &.type-cciav { background-color: $account-caf; }

  &.type-apvc { background-color: $account-cvc; }
}

.regime-row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .mat-mdc-card { cursor: pointer; }
}

.distributed-row {
  align-items: end;

  .origin-mat-card {
    visibility: hidden;
    height: 0;
    margin-bottom: 0;
  }

  .mat-mdc-card {
    margin-top: 0;
  }
}

.flag-distributed-add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: $red-alt;
  border-radius: 30px;
  position: absolute;
  top: -18.5px;
  left: calc(50% - 15px);
  color: $white;
  font-size: 22px;
}

.collector {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $collector-color;
  border-radius: 4px;
  color: $white;
  font-size: 9px;
  letter-spacing: 0.85px;
  font-weight: bold;
  padding: 4px;
  text-transform: uppercase;
}

.mat-mdc-card {
  height: 105px;
  width: calc(50% - 25px);
  min-width: $min-width-card;
  max-width: $max-width-card;
  box-sizing: border-box;
  border-radius: 5px !important;
  background-color: $white;
  box-shadow: 0 0 12px 0 $shadow !important;
  margin: 5px 0;
  padding: 0 !important;
  display: flex !important;

  .fund-description {
    width: 82px;
    height: 100%;
    text-align: center;
    border-right: 1px dashed $border-color-primary;
  }

  .account-description {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  .account-elements {
    display: grid;
    padding: 9px 8px;

    &:first-of-type {
      padding-right: 0;
    }
  }

  .afp-description,
  .type-description,
  .percentage-description {
    .title {
      color: $text-primary;
      font: 11.5px $font-medium;
      line-height: 16px;
      text-align: left;
    }

    .name {
      color: $text-primary;
      font: 12.5px $font-bold;
      text-align: left;
      letter-spacing: 1.19px;
      line-height: 15px;
      text-transform: uppercase;
      @media screen and (max-width: $break-large) {
        font-size: 11.5px;
      }
    }
  }

  .type-description {
    margin-top: 15px;
  }

  .balance {
    color: $text-primary;
    font: 19.5px $font-medium;
    letter-spacing: 0.82px;
    line-height: 25px;
    margin-top: 7px;
    text-align: right;
    @media screen and (max-width: $break-xlarge) {
      font-size: 17.5px;
    }
    @media screen and (max-width: $break-large) {
      font-size: 15.5px;
    }
    @media screen and (max-width: $break-small) {
      font-size: 13.5px;
    }
  }

  &.disabled {
    cursor: pointer;
    box-shadow: none;
    background-color: $selected-background;
    .fund {
      background-color: $disabled-color;
    }
  }

  @media screen and (max-width: $break-large) {
    width: 90%;
    max-width: $max-width-card;
    min-width: $min-width-card;
    margin: 5px auto;
    &.transfered {
      box-shadow: none !important;
      border: 1px solid $border-color-primary;
      border-left: 10px solid $red-primary;
    }
  }
}

.fund {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  color: $white;
  font: 17.5px $font-medium;
  line-height: 41px;
  margin: 16px auto 0;

  &.type-a {
    background-color: $fund-a;
  }

  &.type-b {
    background-color: $fund-b;
  }

  &.type-c {
    background-color: $fund-c;
  }

  &.type-d {
    background-color: $fund-d;
  }

  &.type-e {
    background-color: $fund-e;
  }

  &.disabled {
    background-color: $disabled-color;
  }
}

.fund-title {
  font: 12.5px $font-bold;
  line-height: 20px;
  color: $text-primary;
  margin-top: 5px;
}

.fund-subtitle {
  color: $text-primary;
  font-size: 11.5px;
  margin-top: auto;
}

.transfer-placeholder {
  height: 105px;
  width: calc(50% - 25px);
  min-width: 325px;
  max-width: $max-width-card;
  box-sizing: border-box;
  border: dashed 1px $red-alt;
  border-radius: 2px;
  margin: 5px 0;
  padding: 10px 0;

  .logo-pv-red-alt {
    background-size: auto 80px;
  }

  .i-alert-red-primary {
    margin: 18px;
  }

  &.rejected {
    border-color: $border-color-primary;
    display: flex;
    justify-content: center;
    color: $red-primary;
    font: 13.5px $font-medium;

    .reason-description {
      margin: auto 20px;
      max-width: 350px;
    }

    @media screen and (max-width: $break-large) {
      color: $red-primary;
      margin: -12px auto 10px;
      padding: 20px 0 0;
      order: 10;
      place-self: auto;
      background-color: $error-background;
      border: 1px solid $border-color-primary;
      border-top: none;
      border-radius: 5px;
      height: fit-content;
      .reason-description {
        margin: 0 12px;
        font-size: 11.5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 11.5px;
        max-height: 33.5px;
        -webkit-line-clamp: 3;
      }
      .i-alert-red-primary {
        margin: -4px 0 6px 15px;
      }
    }
  }


}

// ----------------------------- Links -----------------------------
a.disabled {
  &.btn {
    color: $white;
    background-color: $disabled-color;
    pointer-events: none;
    opacity: unset;
  }
}

a:link {
  color: $red-primary;
  font: 12.5px $font-medium;
  text-decoration: none;

  &.btn {
    color: $white;
    height: 24px;
    padding: 10px 44px;
    font-size: 13px;
    text-align: center;
  }
}

a:visited { color: $red-primary; }

a:focus { outline: none; }

.column {
  display: inline;
  text-align: center;
  margin: 40px 40px 20px 40px;
}

.row {
  display: inline-flex;
}

// ----------------------------- Slide toggle -----------------------------

.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar { background-color: $red-tertiary; }

.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb { background-color: $red-primary; }

.mat-mdc-slide-toggle {
  margin: 0 0 -30px auto;
  @media screen and (max-width: $break-large) {
    margin: 0 auto;
  }
}

// ----------------------------- Radio buttons -----------------------------


.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-icon-color: rgb(194, 27, 23);
  --mdc-radio-selected-hover-icon-color: rgb(194, 27, 23);
  --mdc-radio-selected-pressed-icon-color: rgb(194, 27, 23);
  --mat-mdc-radio-checked-ripple-color: unset;
}


// ----------------------------- Checkboxes -----------------------------
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-icon-color: rgb(194, 27, 23);
  --mdc-checkbox-selected-hover-icon-color: rgb(194, 27, 23);
  --mdc-checkbox-selected-pressed-icon-color: rgb(194, 27, 23);
  --mat-mdc-checkbox-checked-ripple-color: unset;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-icon-color: rgb(194, 27, 23);
  --mdc-checkbox-selected-hover-icon-color: rgb(194, 27, 23);
  --mdc-checkbox-selected-pressed-icon-color: rgb(194, 27, 23);
  --mat-mdc-checkbox-checked-ripple-color: unset;
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected~.mdc-checkbox__ripple {
  background: unset;
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $red-tertiary;
  background-color: $selected-background !important;
}

.mat-checkbox-inner-container {
  margin-top: 4px !important;
  @media (max-width: $width-mobile) { margin-top: 0 !important; }
}

@media (max-width: $width-mobile) {
  .mat-checkbox-label {
    font-size: 11.5px;
    white-space: normal;
  }
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 16px;
  }
  .container-button {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

// ----------------------------- Employers form -----------------------------

.employers-form {
  text-align: center;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;

  .main-title {
    padding-top: 40px;
  }

  .section-title {
    text-align: left;
    padding-bottom: 15px;
    border-bottom: 1px dashed $shadow;
    padding-top: 40px;

    button {
      float: right;
      margin-top: -15px;
    }
  }

  .button-mobile {
    display: none;
  }

  .employer-box {
    width: auto;
    box-shadow: 0 2px 8px 0 $shadow;
    margin: 0 auto;
    overflow: hidden;
    height: 94px !important;
    padding: 0;
    align-items: normal;
    border-left: 5px solid $red-primary;
    border-right: 5px solid $red-primary;

    &.error { background-color: $error-background; }

    label {
      display: block;
      text-transform: uppercase;
    }

    .name-section,
    .address-section,
    .identifier-section,
    .delete-section {
      display: inline-block;
      height: 100%;
      box-sizing: border-box;
      vertical-align: top;

      label:first-of-type {
        height: 12px;
        color: $grey-darker;
        font: 10px $font-bold;
        letter-spacing: 0.24px;
        line-height: 12px;
        margin: 18px 0;
      }

      label:last-of-type {
        height: 16px;
        color: $grey-mid-dark;
        font: 13px $font-bold;
        letter-spacing: 0.31px;
        line-height: 16px;
      }
    }

    .name-section {
      width: 26%;
      border-right: 1px solid $border-color-primary;
    }

    .address-section {
      width: 38%;
      border-right: 1px solid $border-color-primary;
    }

    .identifier-section {
      width: 21%;
      border-right: 1px solid $border-color-primary;
    }

    .delete-section {
      width: 15%;
    }
  }

  @media (max-width: $width-mobile) {
    .main-title {
      padding-top: 35px;
      font-size: 16.5px;
    }
    .main-subtitle { font-size: 14px; }
    .button-desktop { display: none; }
    .button-mobile {
      display: block;
      height: 30px;
    }
    .employer-box {
      display: flex;
      justify-content: space-between;

      .name-section {
        label:first-of-type { display: none; }

        border-right: none;
        display: flex;
        align-items: center;
        width: 90%;
        padding-left: 20px;
      }

      .delete-section {
        display: flex;
        align-items: center;
        width: 30%;
      }

      .address-section, .identifier-section { display: none; }
    }
  }
}

.cover-map {
  background-color: rgb(223, 220, 215);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

//------------------------------ Map Alert ---------------------------------

.map-alert {
  .i-location {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  color: $text-primary;
  font: 12px $font-regular;
  text-align: center;
  position: absolute;
  z-index: 9;
}

//------------------------------ Voucher ---------------------------------
.detail-table {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 830px;
  box-shadow: 0 0 12px 0 $shadow;
  flex-direction: row;
  border-radius: 5px;
  margin: 20px 0;
  @media screen and (max-width: $break-xlarge) {
    flex-direction: column;
    max-width: 700px;
  }
  @media screen and (max-width: $break-large) { max-width: 500px; }
  @media screen and (max-width: $break-medium) { max-width: 380px; }
  @media screen and (max-width: $break-small) { max-width: 290px; }

  &.origin {
    background-color: $selected-background;
    box-shadow: none;
  }

  &.auth-table {
    .detail-text {
      padding: 0 20px;
      width: 100%;
      @media screen and (max-width: $break-xlarge) {
        display: flex;
        justify-content: space-between;
        width: auto;
      }
    }

    h4 {
      @media screen and (max-width: $break-xlarge) { float: left; }
    }
  }

  .transfer {
    width: 35%;
    display: flex;
    padding-right: 15px;
    border-right: 1px solid $border-color-primary;
    @media screen and (max-width: $break-xlarge) {
      flex-direction: row;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $border-color-primary;
      padding: 0;
    }
  }

  .detail-text {
    text-align: center;
    width: 100%;
    margin: 5px 0;
    padding-bottom: 5px;
    border-right: 1px solid $border-color-primary;
    @media screen and (max-width: $break-xlarge) { border: 0; }

    &:first-child {
      @media screen and (max-width: $break-xlarge) { border: 0; }
    }

    &:last-child { border-right: 0; }

    .fund-description {
      display: flex;
      justify-content: center;
      align-items: center;

      .detail-fund {

        display: flex;
        flex-direction: column;
        font-size: 11px;
        height: 100%;
        align-items: flex-start;
        justify-content: left;

        .fund-subtitle {
          font-size: 10px;
          margin-top: 0;
        }
      }
    }

    &.origin {
      background-color: $white;
      border-right: none;
      @media screen and (max-width: $break-xlarge) { width: 100%; }
      @media screen and (max-width: $break-small) { width: 85%; }
    }

    .up-title {
      font: 13px $font-regular;
      line-height: 13px;
      margin: 10px 0;
      text-align: center;
    }

    .funding {
      color: $white;
      border-radius: 100%;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: $break-xlarge) { margin-bottom: 10px; }

      &.origin { background-color: $grey; }
    }

    .value {
      height: calc(100% - 35px);
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font: 14px $font-bold;
      color: $text-primary;
      @media screen and (max-width: $break-xlarge) {
        margin: 0;
        font-size: 14px;
        text-align: right
      }

      &.per {
        font-size: 21px;
        margin-top: 21px;
        @media screen and (max-width: $break-xlarge) {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}

.legal-conditons {
  text-align: left;
  margin-top: 30px;
  display: block;
  max-width: none;
  width: auto;
  padding: 20px 40px;
  font-size: 14px;
  color: $text-primary;

  a {
    font: 14px $font-regular;
    text-decoration: underline;
  }

  p, p a { font-size: 11.5px; }

  p { margin-top: 25px; }

  @media screen and (max-width: $break-small) { padding: 20px; }
  @media (max-width: $width-mobile) {
    font-size: 11.5px;
    a { font-size: 11.5px; }
  }
}


.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element { background-color: $red-primary; }

// ----------------------------- breakpoints -----------------------------
@media screen and (max-width: $break-xlarge) {
  app-confirm,
  app-transfer {
    padding: 0;
  }
}

@media screen and (max-width: $break-large) {
  .regime-row {
    flex-direction: column;
  }

  .hidden {
    display: none !important;
  }

  .container-account-type-title,
  .container-section-title {
    display: flex;
    justify-content: center;
  }

  .container-section-title {
    padding: 5px 0;
    width: 90%;
  }


  .i-denied,
  .i-exchange {
    display: none;
  }

  .transfer-placeholder {
    display: none;
    width: 90%;
    max-width: $max-width-card;
    min-width: $min-width-card;
    margin: -5px auto 10px;
    height: 55px;

    &.rejected {
      border: 1px solid $border-color-primary;
      background-color: $error-background;
      border-radius: 5px;

      .reason-description {
        font-size: 11.5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 11.5px;
        max-height: 33.5px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@media screen and (max-width: $break-medium) {
  app-unassisted,
  app-confirm,
  app-transfer,
  app-confirm transfer {
    padding: 0;
  }

  .main-title-regular.header {
    display: none;
  }

  .main-container {
    padding: 0;
  }

  app-confirm,
  app-continue,
  app-assisted,
  app-contact-successful,
  app-online,
  app-questions,
  app-unassisted,
  app-transfer {
    margin: auto;
    border-radius: 5px;
    background-color: $white;
    box-shadow: none;
    display: block;
    overflow: hidden;
    width: 100%;
    max-width: 1300px;
    box-sizing: border-box;
    min-height: 400px;
  }

  .pv-light {
    height: 60px;
    background-size: 100px;
  }

  app-confirm,
  app-unassisted,
  app-transfer {
    padding: 0;
  }
}

@media screen and (max-width: $break-small) {
  body { background-color: $white; }

  // --- Titles ----

  .main-title-bold {
    font: 20px $font-bold;
    margin: 10px 0;
    color: $red-primary;
    text-align: center;

    &.header {
      color: $white;
    }

    &.description {
      color: $text-primary;
    }
  }

  .main-title {
    font: 20px $font-medium;
    margin: 10px 15px;
    color: $red-primary;
    text-align: center;

    &.header {
      color: $white;
    }

    &.description {
      color: $text-primary;
    }
  }

  .main-title-regular {
    font: 20px $font-regular;
    margin: 10px 0;
    color: $red-primary;
    text-align: center;

    &.header {
      color: $white;
    }

    &.description {
      color: $text-primary;
    }
  }

  .main-subtitle {
    color: $grey-dark;
    font-size: 14px;
    margin: 10px 25px 30px;
    text-align: center;
  }

  app-confirm,
  app-unassisted,
  app-transfer {
    padding: 0;
  }

  app-header {
    padding: 0;
  }

  .pv-light {
    height: 60px;
  }

  .main-title-regular.header {
    display: none;
  }

  .main-container {
    padding: 0;
  }

  app-confirm,
  app-continue,
  app-assisted,
  app-contact-successful,
  app-online,
  app-unassisted,
  app-transfer {
    margin: auto;
    border-radius: 5px;
    background-color: $white;
    box-shadow: none;
    display: block;
    overflow: hidden;
    width: 100%;
    max-width: 1300px;
    box-sizing: border-box;
    min-height: 400px;
  }

  app-footer {
    padding: 70px 0 30px;
    background-color: $white;

    .aligned-box {
      justify-content: center;
    }

    @media screen and (max-width: $break-xlarge) {
      padding-top: 0;
    }
  }

  .transfer-placeholder {
    display: none;
  }

  .mat-mdc-card {
    .type-description {
      .title {
        font: 10.5px $font-medium;
        line-height: 15px;
      }
    }
  }
}

.modal-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  box-sizing: border-box;
}

.cancel-phone-transfer-modal-container {
  .btn-large {
    margin-bottom: 35px;
  }
}


.mat-dialog-container-border {
  .mat-mdc-dialog-container {
    border-radius: 20px !important;
  }
  @media screen and (max-width: $break-small-mid) {
    width: 100vw !important;
    height: 100% !important;
    max-width: none !important;
    .mat-mdc-dialog-container {
      border-radius: 0 !important;
    }
}

}

// Fund color
.type-a { background-color: $fund-a;}

.type-b { background-color: $fund-b;}

.type-c { background-color: $fund-c;}

.type-d { background-color: $fund-d;}

.type-e { background-color: $fund-e;}

// Disabled colors
.disabled { background-color: $disabled-color;}

.field {
  width: 100%;
  max-width: 48%;
  @media screen and (max-width: $break-medium) { max-width: 100%; }
}

//Recaptcha
.disclaimer {
  color: var(--ion-color-medium);
  font-size: 12px;
  margin-bottom: 10px;
}

.grecaptcha-badge {
  visibility: hidden;
  bottom: 100px !important;
  z-index: 999;
}

body.recaptcha .grecaptcha-badge {
  visibility: visible;
}


//Placeholder de los inputs en cursiva
::-webkit-input-placeholder {
   font-style: italic;
}
:-moz-placeholder {
   font-style: italic;  
}
::-moz-placeholder {
   font-style: italic;  
}
:-ms-input-placeholder {  
   font-style: italic; 
}

app-contact {
  height: auto;
  max-height: 100vh;
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  color: $red-primary;
}

.cdk-overlay-pane {
  overflow: auto;
  max-width: unset !important;
  padding: unset;
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    padding: 24px
  }
}

.mat-mdc-tab-group {
  .mdc-tab {
    flex:none;
    white-space: unset;
  }
  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: transparent !important;
  }
}